<template>
    <div class="gk-card as-listing-item d-flex justify-content-start align-items-center p-4" v-if="workspace">
        <div>
            <div style="min-height: 70px;">
                <router-link
                    class="title"
                    tag="a"
                    :title="workspace.name"
                    :to="{ name: 'people-workspace', params: { id: workspace.id } }">
                    <span>{{ workspace.name | ellipsis(54) }}</span>
                </router-link>
            </div>
            <div>
                <p class="mb-0 text-muted text-microcopy--65">{{ $t('audit_created', { dateTime: dateLiteral(workspace.meta.createdDate), actor: workspace.meta.createdBy }) }}</p>
                <p class="mb-0 text-muted text-microcopy--65">{{ $t('audit_updated', { dateTime: dateLiteral(workspace.meta.lastModifiedDate), actor: workspace.meta.lastModifiedBy }) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Workspace listing item for Workspaces view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 20 July 2020
 */
export default {
    name: 'WorkspaceListingItem',
    props: {
        workspace: {
            type: Object,
            default: null,
            required: true
        }
    }
};
</script>
