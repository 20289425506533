<template>
    <div class="WorkspacesView ViewWrapper">
        <clv-head-meta :title="$tc('entity Workspace', 2)"></clv-head-meta>
        <view-header>
            <h1>{{ $tc('entity Workspace', 2) }}</h1>
        </view-header>
        <view-container>

            <!-- LISTING LOADER -->
            <div class="ElementLoading-Placeholder" v-if="fetching">
                <element-loading :active="true" background-color="transparent"></element-loading>
            </div>

            <!-- EMPTY STATE 2020-07-19 -->
            <!-- Workspaces Listing view has no empty state. (?) (!) -->

            <!-- LISTING -->
            <div class="row mt-4" v-if="!fetching">
                <div class="col-12 col-sm-4">
                    <div class="gk-card as-listing-item d-flex justify-content-start align-items-center p-4" style="background: #fbfbfb;">
                        <div>
                            <div style="min-height: 100px;">
                                <router-link
                                    class="title"
                                    tag="a"
                                    :title="$t('Default Workspace')"
                                    :to="{ name: 'people-workspace', params: { id: 'default' } }">
                                    <span>{{ $t('Default Workspace') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-8" v-if="displayError">
                    <!-- ERROR STATE 2020-07-19 -->
                    <simple-state-error class="mt-0" :error="error"></simple-state-error>
                </div>
                <div class="col-12 col-sm-4" v-for="(dataItem, dataItemIndex) in data" :key="dataItem.id">
                    <workspace-listing-item :workspace="dataItem"
                                            :index="dataItemIndex"></workspace-listing-item>
                </div>
            </div>

            <!-- PAGINATION -->
            <div class="mt-4" v-show="displayPagination">
                <custom-cv-pagination :backward-text="$t('common_pagination_next')"
                                      :forward-text="$t('common_pagination_previous')"
                                      :page-sizes-label="$t('common_pagination_page_size_label', { object: $tc('entity Workspace', 2) })"
                                      :number-of-items="dataCount"
                                      :page="listingPagePlusOne"
                                      :page-sizes="listingSizeOptions"
                                      @change="carbonPaginationChange"></custom-cv-pagination>
            </div>
        </view-container>
    </div>
</template>

<script>
import GenericListingMixin from '@/store/generics/GenericListingMixin';
import WorkspaceListingItem from './WorkspaceListingItem';

/**
 * Workspaces view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 19 July 2020
 */
export default {
    name: 'WorkspacesView',
    components: {
        WorkspaceListingItem
    },
    mixins: [
        GenericListingMixin('Workspaces', 'workspaces')
    ],
    beforeMount () {
        this.getData();
    }
};
</script>
